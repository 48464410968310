<template>
    <div>
        <ConfirmDialog ref="confirmRemove" />
        <div class="addAction mb-3 me-3">
            <h4 class="form-title">Office Locations</h4>

            <CButton ref="createForm" color="primary" variant="outline" class="p-0" @click="showModal($event)">
                Add Office
            </CButton>
        </div>
        <SmartTable
            :name="'table-setting-account-company-details-office'"
            :items="offices"
            :columns="officeFields.map((i) => ({ ...i, sorter: false }))"
            infinity>
            <template #path="{ item }">
                <CIcon v-if="item.path" class="pointer" name="cil-description" @click="goWebsite(item.path)" />
            </template>
            <template #button="{ item }">
                <div class="d-flex">
                    <CIcon name="cil-pen" class="mx-2" @click="showModal($event, item)" />
                    <CIcon name="cil-trash" class="mx-2" @click="handleRemove(item)" />
                </div>
            </template>
        </SmartTable>

        <CompanyDetailsModal title="Add Office" :large="true" :visible="showForm" @close="() => (showForm = false)">
            <ConfirmDialog ref="confirmDialog" />

            <CInputGroup>
                <CNInput
                    v-model="form.name"
                    required
                    max-length="255"
                    label="Enter Office"
                    :invalid="!!validationErrors.name"
                    @blur="handleChangeField('name')" />

                <CFormText v-if="validationErrors.name" style="color: red">
                    {{ validationErrors.name }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput
                    v-model="form.address"
                    required
                    max-length="255"
                    label="Enter Street Address"
                    :invalid="!!validationErrors.address"
                    @blur="handleChangeField('address')" />

                <CFormText v-if="validationErrors.address" style="color: red">
                    {{ validationErrors.address }}
                </CFormText>
            </CInputGroup>

            <div class="double-form">
                <CInputGroup>
                    <CNSelect
                        v-model="form.country_id"
                        required
                        class="w-100"
                        label="Select Country"
                        :error="validationErrors.country_id"
                        :invalid="!!validationErrors.country_id"
                        :options="countriesOptions.map((c) => ({ ...c, name: c.label }))"
                        search
                        @change="handleChangeField('country_id')" />
                </CInputGroup>

                <CInputGroup>
                    <CNSelect
                        v-model="form.state_id"
                        required
                        class="w-100"
                        label="Select State"
                        :error="validationErrors.state_id"
                        :invalid="!!validationErrors.state_id"
                        :options="states.map((c) => ({ ...c, name: c.label }))"
                        search
                        @change="handleChangeField('state_id')" />
                </CInputGroup>
            </div>

            <div class="double-form">
                <div style="width: 50%">
                    <CNInput
                        v-model="form.city"
                        required
                        max-length="255"
                        label="Enter City"
                        :invalid="!!validationErrors.city"
                        @blur="handleChangeField('city')" />

                    <CFormText v-if="validationErrors.city" style="color: red">
                        {{ validationErrors.city }}
                    </CFormText>
                </div>

                <div style="width: 50%">
                    <CNSelect
                        v-model="form.is_headquarters"
                        label="Headquarters"
                        :invalid="!!validationErrors.is_headquarters"
                        :options="headquarters"
                        @blur="handleChangeField('is_headquarters')">
                    </CNSelect>

                    <CFormText
                        v-if="validationErrors.is_headquarters"
                        :style="`color: ${validationErrors.is_headquarters && 'red'}`">
                        {{ validationErrors.is_headquarters }}
                    </CFormText>
                </div>
            </div>

            <div class="double-form">
                <CInputGroup>
                    <CNInput
                        v-model="form.county"
                        label="County"
                        :invalid="!!validationErrors.county"
                        @blur="handleChangeField('county')" />

                    <CFormText v-if="validationErrors.county" style="color: red">
                        {{ validationErrors.county }}
                    </CFormText>
                </CInputGroup>

                <CInputGroup>
                    <CNInput
                        v-model="form.zip_code"
                        v-mask="['#####']"
                        required
                        label="Enter ZIP"
                        :invalid="!!validationErrors.zip_code"
                        @blur="handleChangeField('zip_code')" />

                    <CFormText v-if="validationErrors.zip_code" style="color: red">
                        {{ validationErrors.zip_code }}
                    </CFormText>
                </CInputGroup>
            </div>

            <div class="double-form">
                <CInputGroup>
                    <CNInput
                        v-model="form.phone"
                        v-mask="['(###) ###-####']"
                        required
                        label="Enter Phone"
                        :invalid="!!validationErrors.phone"
                        @blur="handleChangeField('phone')" />

                    <CFormText v-if="validationErrors.phone" style="color: red">
                        {{ validationErrors.phone }}
                    </CFormText>
                </CInputGroup>

                <CInputGroup>
                    <CNInput
                        v-model="form.fax"
                        v-mask="['(###) ###-####']"
                        label="Enter Fax"
                        :invalid="!!validationErrors.fax"
                        @blur="handleChangeField('fax')" />

                    <CFormText v-if="validationErrors.fax" style="color: red">
                        {{ validationErrors.fax }}
                    </CFormText>
                </CInputGroup>
            </div>

            <template #footer>
                <CButton color="primary" variant="outline" class="w-100" @click="cancel"> Cancel</CButton>

                <CButton color="primary" class="w-100" @click="handleSubmit">
                    <CSpinner v-if="loading" color="light" size="sm" />
                    Save
                </CButton>
            </template>
        </CompanyDetailsModal>
    </div>
</template>
<script>
import UploadFile from '@/components/UploadFile';
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import { parseError } from '@/utils/api';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import CSelectSearch from '@/components/CSelectSearch';
import { mask } from 'vue-the-mask';
import { equalObjectsByKeys } from '@/utils/helper';
import CompanyDetailsModal from '@/components/Modals/CompanyDetailsModal.vue';

export default {
    name: 'Office',
    directives: { mask },
    components: { CompanyDetailsModal, ConfirmDialog, UploadFile, CSelectSearch },
    inject: ['toast'],
    props: {
        offices: { type: Array, default: () => [] },
        countries: { type: Array, default: () => [] },
    },
    emits: ['updateData'],
    data() {
        return {
            error: '',
            loading: false,
            headquartersOfficeName: '',
            showForm: false,
            headquarters: [
                { value: '0', name: 'No' },
                { value: '1', name: 'Yes' },
            ],
            officeFields: [
                { key: 'name', label: 'Office' },
                { key: 'address', label: 'Street Address' },
                { key: 'city', label: 'City' },
                { key: 'state', label: 'State' },
                { key: 'county', label: 'County' },
                { key: 'zip_code', label: 'Zip' },
                { key: 'country', label: 'Country' },
                { key: 'phone', label: 'Phone' },
                { key: 'fax', label: 'Fax' },
                {
                    key: 'is_headquarters',
                    label: 'Headquarters',
                    format: (value) => (value === '1' ? 'Yes' : 'No'),
                },
                {
                    key: 'path',
                    label: 'File',
                    _props: { class: 'tableIconCenter' },
                    _style: 'min-width: 10px',
                },
            ],
            form: {
                name: '',
                address: '',
                city_id: '',
                county: '',
                state_id: '',
                country_id: '',
                phone: '',
                fax: '',
                path: null,
                is_headquarters: '',
            },
            origin: {},
            validator: {},
            validationErrors: {},
            states: [],
        };
    },
    computed: {
        start() {
            return this.form.id ? null : new Date();
        },
        countriesOptions() {
            let res = [];
            this.countries.forEach((el) => {
                res.push({ value: el.state_id, label: el.state_name });
            });

            return res;
        },
    },
    watch: {
        'form.country_id': function (newVal) {
            if (newVal) {
                this.$http.app.getStates({ params: { country_id: newVal } }).then((res) => {
                    this.states = [];
                    res.data.data.forEach((el) => {
                        this.states.push({ value: el.state_id, label: el.state_name });
                    });
                });
            } else {
                this.form.state_id = undefined;
                this.states = [];
            }
        },
    },
    created() {
        this.$nextTick(() => this.setRules());
    },
    methods: {
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
                address: [rules.required, rules.strMax(255)],
                county: [rules.strMax(255)],
                city: [rules.required, rules.strMax(255)],
                state_id: [rules.required],
                country_id: [rules.required],
                phone: [rules.required, rules.strMinPhone(14, 10), rules.strMax(255)],
                fax: [rules.nullable, rules.strMinPhone(14, 10), rules.strMax(255)],
                zip_code: [rules.required, rules.strMin(5), rules.strMax(10)],
                path: [
                    rules.nullable,
                    () => (typeof this.form.path === 'object' ? 0 : -1),
                    rules.extensions(['.xlsx', '.doc', '.docx', '.xls', '.pdf', '.jpeg', '.png', '.jpg']),
                    rules.fileSize({ size: 10, type: 'MB' }),
                ],
            });
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(field, this.form[field]);
        },
        showModal(e, office = undefined) {
            e.preventDefault();
            if (office) {
                this.form = JSON.parse(JSON.stringify(office));
            } else {
                for (let key in this.form) {
                    if (key === 'county') {
                        this.form[key] = '';
                    } else {
                        this.form[key] = null;
                    }
                }
            }
            this.validationErrors = {};
            this.form.state_id = this.form.state_id || undefined;
            this.origin = JSON.parse(JSON.stringify(this.form));
            this.showForm = true;
        },
        checkForm() {
            return equalObjectsByKeys(this.form, this.origin, Object.keys(this.origin));
        },
        async cancel() {
            if (!this.checkForm()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.showForm = false;
                            for (const key in this.form) {
                                this.form[key] = '';
                            }
                        }
                    });
            } else {
                this.showForm = false;
            }
        },
        getHeadquartersOffice() {
            let name = '';
            this.offices.forEach((item) => {
                if (item.is_headquarters === '1' && item.id !== this.form.id) {
                    name = item.name;
                }
            });

            return name;
        },
        async handleSubmit() {
            this.headquartersOfficeName = this.getHeadquartersOffice();

            if (this.form.is_headquarters === '1' && this.headquartersOfficeName) {
                await this.$refs.confirmDialog
                    .confirm({
                        text:
                            'You have already selected the ' +
                            this.headquartersOfficeName +
                            ` as an Headquarters office. Are you sure you want to change your Headquarters office to ${this.form.name}?`,
                        reverse: true,
                        confirmText: 'Yes, change',
                        cancelText: 'Cancel',
                    })
                    .then((res) => {
                        if (res) {
                            this.submit();
                        }
                    });
            } else {
                this.submit();
            }
        },
        makeFormData() {
            const formData = new FormData();
            for (let key in this.form) {
                if (key === 'is_headquarters') {
                    formData.append('headquarters', this.form[key]);
                } else {
                    this.form[key] && formData.append(key, this.form[key] || '');
                }
            }
            return formData;
        },
        getError(error) {
            this.error = error;
        },
        submit() {
            const validationResult = this.validator.validateAll(this.form);

            if (!validationResult.hasErrors && this.error === '') {
                const action = this.form.id
                    ? this.$http.setting.updateCompanyOffice(this.form.id, this.makeFormData())
                    : this.$http.setting.addCompanyOffice(this.makeFormData());

                this.loading = true;

                action
                    .then((response) => {
                        this.toast('success', response.data.message);
                        this.showForm = false;
                        this.$emit('updateData');
                    })
                    .catch((err) => {
                        err = parseError(err);
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages;
                        } else {
                            this.toast('error', err.message);
                        }
                    })
                    .finally(() => (this.loading = false));
            } else {
                this.validationErrors = validationResult.validationErrors;
            }
        },
        async handleRemove(item) {
            await this.$refs.confirmRemove
                .confirm({
                    text: 'Are you sure you want to delete office location "' + item.name + '"?',
                    confirmText: this.$t('Delete'),
                    cancelText: this.$t('Cancel'),
                    reverse: true,
                })

                .then((res) => {
                    if (res) this.remove(item.id);
                });
        },
        remove(id) {
            this.$http.setting
                .removeCompanyOffice({ office_id: id })
                .then((response) => {
                    this.toast('success', response.data.message);
                    this.$emit('updateData');
                })
                .catch((error) => {
                    error = parseError(error);
                    this.toast('error', error.message);
                });
        },
        goWebsite(url) {
            window.open(url[0] !== 'h' ? 'https://' + url : url, '_blank');
        },
    },
};
</script>

<style>
.double-form {
    display: flex;
    gap: 16px;
}
</style>
