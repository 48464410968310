import LoadingSelect from './components/LoadingSelect';
import AppPreloader from '@/components/Preloaders/AppPreloader';
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import {parseError} from '@/utils/api';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import LogoInput from '@/components/LogoInput';
import TaxId from './components/TaxId';
import Certification from './components/Certification';
import SubsidiaryCompanies from './components/SubsidiaryCompanies';
import Licence from './components/Licence';
import Office from './components/Office';
import AccordionItem from '@/components/Page/AccordionItem';
import {equalObjectsByKeys} from '@/utils/helper';
import $p from '@/utils/constants/permissions';
import StickyFooter from '@/components/Forms/Partials/StickyFooter.vue';

export default {
    async beforeRouteLeave() {
        if (this.isEditedForm() && !this.confirmedLeave) {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'You have unsaved changes.\n' + 'Are you sure, you want to leave the page?',
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        return true;
                    } else {
                        return Promise.reject(false);
                    }
                });
        } else {
            return true;
        }
    },
    inject: ['toast'],
    components: {
        StickyFooter,
        LoadingSelect,
        LogoInput,
        TaxId,
        Certification,
        AppPreloader,
        Licence,
        Office,
        AccordionItem,
        ConfirmDialog,
        SubsidiaryCompanies,
    },
    data() {
        return {
            permissions: $p,
            mountedComponent: false,
            loading: false,
            confirmedLeave: false,
            routeTo: '',
            origin: {
                company: {},
                company_types: [],
            },
            defaultCompanyName: '',
            uploadedFile: null,
            form: {
                logo: null,
                name: '',
                dba: '',
                website: '',
                id: '',
                tax: '',
                primary_contact_id: null,
                primary_contact_name: '',
                default_contract_signer_id: null,
                default_contract_signer_name: '',
                default_task_assignee_id: null,
                default_task_assignee_name: '',
                company_type: '',
                last_modified_date: null,
            },
            formCompanyTypes: [],
            certifications: [],
            subsidiaryCompanies: [],
            states: [],
            countries: [],
            licenses: [],
            offices: [],
            validator: {},
            validationErrors: {},
            referenceBookParams: ['company_primary_contacts', 'company_default_signers_or_assigneers'],
            companyPrimaryContacts: [],
            companyDefaultSignersOrAssigners: [],
            formKeys: [
                'name',
                'dba',
                'website',
                'tax',
                'logo',
                'primary_contact_id',
                'default_contract_signer_id',
                'default_task_assignee_id',
            ],
            companyUsersLead: null,
            companyUsersSigners: null,
            companyUsersAssigners: null,
        };
    },
    computed: {
        formAvailable() {
            return this.companyUsersSigners && this.companyUsersLead && this.companyUsersAssigners;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true;
        });
    },
    created() {
        this.$nextTick(() => {
            this.setRules();
            this.fetchData();

            this.$http.app.getStates({params: {country_id: 1}}).then((res) => (this.states = res.data.data));

            this.$http.app.fetchCountries().then((res) => (this.countries = res.data.data));

            this.fetchReferenceBook();
            this.getCompanyUsers();
        });
    },
    methods: {
        getCompanyUsers() {
            this.$http.companies
                .getCompanyUsers({
                    params: {
                        search: '',
                        roles: this.roles,
                        permission: this.permission,
                    },
                })
                .then((response) => {
                    this.companyUsersLead = response.data.data;
                })
                .finally(() => (this.loading = false));

            this.$http.companies
                .getCompanyUsers({
                    params: {
                        search: '',
                        roles: this.roles,
                        permission: this.permissions.TASKS_AND_DOCUMENTS_SIGN,
                    },
                })
                .then((response) => {
                    this.companyUsersSigners = response.data.data;
                })
                .finally(() => (this.loading = false));

            this.$http.companies
                .getCompanyUsers({
                    params: {
                        search: '',
                        roles: this.roles,
                        permission: this.permissions.TASKS_AND_DOCUMENTS_ASSIGN,
                    },
                })
                .then((response) => {
                    this.companyUsersAssigners = response.data.data;
                })
                .finally(() => (this.loading = false));
        },
        fetchReferenceBook() {
            this.$http.common
                .fetchReferenceBook({
                    params: {
                        only: this.referenceBookParams.join(','),
                    },
                })
                .then(
                    ({
                         data: {
                             data: {company_primary_contacts, company_default_signers_or_assigneers},
                         },
                     }) => {
                        this.companyPrimaryContacts = company_primary_contacts.map((el) => ({
                            value: el.id,
                            label: el.full_name,
                        }));
                        this.companyDefaultSignersOrAssigners = company_default_signers_or_assigneers.map((el) => ({
                            value: el.id,
                            label: el.full_name,
                        }));
                    },
                );
        },
        isEditedForm() {
            return !equalObjectsByKeys(this.origin.company, this.form, this.formKeys) || this.isEditCompanyTypes();
        },
        isEditCompanyTypes() {
            for (const [key, value] of Object.entries(this.origin.company_types)) {
                if (this.formCompanyTypes[key]['enabled'] !== value['enabled']) {
                    return true;
                }
            }

            return false;
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(field, this.form[field]);
        },
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
                dba: [rules.strMax(100)],
                website: [rules.required, rules.url, rules.strMax(255)],
                tid: [rules.required, rules.strSize(10)],
                default_task_assignee_id: [rules.required, rules.number],
                default_contract_signer_id: [rules.required, rules.number],
                primary_contact_id: [rules.required, rules.number],
                logo: [],
            });
        },
        fetchData(tableUpdate = false) {
            this.loading = true;
            this.$http.setting
                .getCompanyInfo()
                .then((response) => {
                    let res = response.data.data;
                    this.certifications = res.certifications;
                    this.subsidiaryCompanies = res.subsidiary_companies;
                    this.licenses = res.licenses;
                    this.offices = res.offices.map((office) => ({
                        ...office,
                        is_headquarters: office.is_headquarters ? '1' : '0',
                    }));

                    if (!tableUpdate) {
                        this.origin.company = res.company;
                        this.origin.company_types = res.company_types;
                        this.form = this.$deepClone(res.company);
                        this.formCompanyTypes = this.$deepClone(res.company_types);

                        this.defaultCompanyName = res.company.name;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        showFile(file) {
            if (file.target.value.error) {
                this.validationErrors['logo'] = file.target.value.error;
            } else if (file.target.value instanceof Blob) {
                this.uploadedFile = file.target.value;
                this.validationErrors['logo'] = '';
            }
        },
        makeFormData() {
            const formData = new FormData();

            for (let key in this.form) {
                formData.append(key, this.form[key] || '');
            }

            for (let i = 0; i < this.formCompanyTypes.length; i++) {
                for (let key in this.formCompanyTypes[i]) {
                    formData.append('company_types[' + i + '][' + key + ']', this.formCompanyTypes[i][key]);
                }
            }

            if (this.uploadedFile instanceof Blob) {
                formData.set('logo', this.uploadedFile);
            } else {
                formData.delete('logo');
            }

            return formData;
        },
        submit() {
            const validationResult = this.validator.validateAll(this.form);
            if (!validationResult.hasErrors) {
                this.$http.setting
                    .updateCompanyInfo(this.makeFormData())
                    .then((response) => {
                        this.uploadedFile = null;
                        this.toast('success', response.data.message);
                        this.fetchData();
                    })
                    .catch((err) => {
                        err = parseError(err);
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages;
                        } else {
                            this.toast('error', err.message);
                        }
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    })
                    .finally(() => window.scrollTo({top: 0, behavior: 'smooth'}));
            } else {
                this.validationErrors = validationResult.validationErrors;
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        },
        async cancel() {
            if (this.isEditedForm()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.fetchData();
                            this.validationErrors = {};
                        }
                    });
            } else {
                this.fetchData();
            }
        },
    },
};
